import { useRef } from "react";
import useDoubleClick from "use-double-click";

export default function DoubleClickButton({
  className,
  onDoubleClick,
  children,
}) {
  const buttonRef = useRef();

  useDoubleClick({
    onDoubleClick: onDoubleClick,
    ref: buttonRef,
    latency: 250,
  });

  return (
    <button className={className} ref={buttonRef}>
      {children}
    </button>
  );
}
