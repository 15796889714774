import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { POSTGREST_BASE_URL } from "../../config";
import uniqBy from "lodash.uniqby";

function Highlight({ term, content }) {
  const [before, after] = content.split(term);
  return (
    <div>
      {before}
      <span className="font-semibold">{term}</span>
      {after}
    </div>
  );
}

export default function OrderScannerManual({ onScanned }) {
  const [selected, setSelected] = useState("");
  const [query, setQuery] = useState("");

  const { data: products = [] } = useQuery(
    [query],
    () =>
      fetch(
        `${POSTGREST_BASE_URL}/supplier_product?select=*&or=(sku.ilike.*${query}*,barcode.ilike.*${query}*)&limit=10`
      ).then((res) => res.json()),
    { enabled: query.length > 2 }
  );

  const uniqProducts = uniqBy(products, "sku");

  return (
    <div className="pt-4">
      <div className="mb-2 font-medium text-left">
        ...ou le rentrer manuellement
      </div>
      <div className="flex w-1/2 space-x-1">
        <Combobox value={selected} onChange={setSelected}>
          <div className="relative w-full">
            <div className="relative w-full overflow-hidden text-left bg-white cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-indigo-300 focus-visible:ring-offset-2 sm:text-sm">
              <Combobox.Input
                className="w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 border border-gray-300 rounded shadow-sm outline-none"
                displayValue={(productSku) => productSku}
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {products.length === 0 && query !== "" ? (
                  <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                    Pas de résultat.
                  </div>
                ) : (
                  uniqProducts.map((product) => (
                    <Combobox.Option
                      key={product.sku + product.supplier_id}
                      className={({ active }) =>
                        `text-left cursor-default select-none relative py-2 pl-10 pr-4 ${
                          active ? "text-white bg-indigo-600" : "text-gray-900"
                        }`
                      }
                      value={product.sku}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            <Highlight
                              term={query}
                              content={`${product.sku} ${
                                product.barcode ? `(${product.barcode})` : ""
                              }`}
                            />
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-indigo-600"
                              }`}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => onScanned(selected)}
        >
          Valider
        </button>
      </div>
    </div>
  );
}
