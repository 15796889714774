import { useEffect } from "react";
import { scanImageData } from "@undecaf/zbar-wasm";

export default function Scanner({ onScanned }) {
  useEffect(() => {
    const usingOffscreenCanvas = isOffscreenCanvasWorking();
    const canvas = document.querySelector("#canvas");
    const video = document.querySelector("#video");

    let offCanvas;
    let requestId = null;

    function isOffscreenCanvasWorking() {
      try {
        return Boolean(new OffscreenCanvas(1, 1).getContext("2d"));
      } catch {
        return false;
      }
    }

    function detect(source) {
      const ctx = canvas.getContext("2d");

      function getOffCtx2d(width, height) {
        if (usingOffscreenCanvas) {
          if (
            !offCanvas ||
            offCanvas.width !== width ||
            offCanvas.height !== height
          ) {
            // Only resizing the canvas caused Chromium to become progressively slower
            offCanvas = new OffscreenCanvas(width, height);
          }

          return offCanvas.getContext("2d");
        }
      }

      canvas.width = source.naturalWidth || source.videoWidth || source.width;
      canvas.height =
        source.naturalHeight || source.videoHeight || source.height;

      if (canvas.height && canvas.width) {
        const offCtx = getOffCtx2d(canvas.width, canvas.height) || ctx;

        offCtx.drawImage(source, 0, 0);

        const imageData = offCtx.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );

        return scanImageData(imageData).then((symbols) => {
          symbols.forEach((symbol) => {
            const lastPoint = symbol.points[symbol.points.length - 1];
            ctx.moveTo(lastPoint.x, lastPoint.y);
            symbol.points.forEach((point) => ctx.lineTo(point.x, point.y));

            ctx.lineWidth = Math.max(
              Math.min(canvas.height, canvas.width) / 100,
              1
            );
            ctx.strokeStyle = "#00e00060";
            ctx.stroke();
          });

          symbols.forEach((s) => (s.rawValue = s.decode("utf-8")));

          const barcodeResult = symbols[0]?.rawValue || "";
          if (barcodeResult) {
            onScanned(barcodeResult);
          }
        });
      } else {
        return Promise.resolve();
      }
    }

    function detectVideo(active) {
      if (active) {
        detect(video).then(
          () => (requestId = requestAnimationFrame(() => detectVideo(true)))
        );
      } else {
        cancelAnimationFrame(requestId);
        requestId = null;
      }
    }

    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: "environment",
          width: { min: 355, ideal: 1280, max: 1920 },
          height: { min: 200, ideal: 720, max: 1080 },
        },
      })
      .then((stream) => {
        document.querySelector(".viewport > #video").style.display = "block";
        video.srcObject = stream;
        detectVideo(true);
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      if (video.srcObject) {
        video.srcObject.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  return (
    <div className="container mt-3 text-left">
      <div className="mb-2 font-medium text-left">
        Scanner le code barre via la caméra
      </div>
      <div className="relative inline-block viewport">
        <canvas id="canvas" className="hidden"></canvas>
        <img id="img" crossOrigin="anonymous" />
        <video
          id="video"
          muted
          autoPlay
          playsInline
          className="hidden max-w-100"
        ></video>
      </div>
    </div>
  );
}
