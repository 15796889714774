import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import formatRelative from "date-fns/formatRelative";
import { fr } from "date-fns/locale";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { BookmarkIcon } from "@heroicons/react/outline";
import Badge from "../ui/Badge";
import OrderStatusBadge, { getOrderStatus } from "./OrderStatusBadge";
import DoubleClickButton from "../ui/DoubleClickButton";

const CUSTOMERS = [
  "👨‍🦳",
  "🧔‍♂️",
  "👳‍♂️",
  "👮",
  "👷",
  "💂‍♀️",
  "🕵️",
  "🧑‍⚕️",
  "👨‍🌾",
  "👨‍🍳",
  "🧑‍🎓",
  "👨‍🎤",
  "👨‍🏫",
  "👩‍🏭",
  "👨‍💻",
  "👨‍💼",
  "👩‍🔧",
  "👨‍🔬",
  "🧑‍🎨",
  "👩‍🚒",
  "👨‍✈️",
  "🧑‍🚀",
  "👨‍⚖️",
  "👰‍♀️",
  "🤵‍♂️",
  "🥷",
];

function Customer({ customer, address }) {
  const { first_name, last_name, email } = customer;
  const { address1, address2, zip, city } = address;
  const [customerEmoji, setCustomerEmoji] = useState("");

  useEffect(() => {
    setCustomerEmoji(CUSTOMERS[Math.floor(Math.random() * CUSTOMERS.length)]);
  }, []);

  return (
    <div>
      <div className="text-lg font-medium">{customerEmoji} Client</div>
      <div className="mt-2">
        <div className="font-medium text-gray-800">
          {first_name} {last_name}
        </div>
        <div className="text-sm text-gray-600">
          {email} - {address.phone}
        </div>
        <div className="text-sm text-gray-600">
          {[address1, address2, `${zip} ${city}`]
            .map((a) => a?.trim())
            .filter(Boolean)
            .join(", ")}
        </div>
      </div>
    </div>
  );
}

function InventoryStatus({ localSupplierProduct, quantityOrdered }) {
  if (!localSupplierProduct) {
    return (
      <Badge backgroundColor="bg-red-100" textColor="text-red-800">
        Épuisé
      </Badge>
    );
  }
  if (localSupplierProduct.inventory < quantityOrdered) {
    return (
      <Badge backgroundColor="bg-orange-100" textColor="text-orange-800">
        {localSupplierProduct?.inventory || 0}/{quantityOrdered} dispo
      </Badge>
    );
  }
  return (
    <Badge backgroundColor="bg-green-100" textColor="text-green-800">
      Dispo
    </Badge>
  );
}

function ScanOrderLineItemFromButton({ order, orderLineItem, onScannedItem }) {
  return (
    <div>
      {!orderLineItem.scanned_at && (
        <DoubleClickButton
          className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onDoubleClick={() => onScannedItem({ order, orderLineItem })}
        >
          Double-cliquez pour scanner
        </DoubleClickButton>
      )}
    </div>
  );
}

function LineItemList({
  order,
  orderStatus,
  scannedLineItemId,
  lineItems,
  onValidatedOrder,
  onScannedItem,
}) {
  return (
    <div>
      <div className="flex justify-between text-lg font-medium">
        <div>📦 Articles</div>
        {orderStatus === "AWAITING_VALIDATION" && (
          <div className="flex justify-start">
            <DoubleClickButton
              className="inline-flex items-center px-2 py-0 text-xs font-medium leading-4 text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onDoubleClick={() => onValidatedOrder({ order })}
            >
              Double-cliquez pour valider
            </DoubleClickButton>
          </div>
        )}
      </div>
      <div className="flex flex-col mt-3 text-sm text-gray-900">
        {lineItems.map((li) => {
          const variantTitle = li.shopify_product_variant?.title;
          const shopifyProduct = li.shopify_product_variant?.shopify_product;
          const productTitle = shopifyProduct?.title;
          const title =
            `${productTitle} ${
              variantTitle === "Default Title" ? "" : variantTitle
            }`
              .split("undefined")
              .join("")
              .trim() || "Produit supprimé";
          const localSupplierProduct =
            li.shopify_product_variant?.supplier_product_shopify_product_option?.find(
              (spspo) => {
                return (
                  spspo.supplier_product.supplier.name === "g8-oxmoto.fr" &&
                  spspo.supplier_product.inventory > 0
                );
              }
            )?.supplier_product;
          return (
            <div key={li.line_item_id} className="my-1 rounded-lg bg-gray-50">
              <Disclosure
                as="div"
                defaultOpen={scannedLineItemId === li.line_item_id}
              >
                {({ open }) => (
                  <>
                    <Disclosure.Button as="div">
                      <div
                        key={li.line_item_id}
                        className="flex items-center px-3 py-3 hover:cursor-pointer hover:bg-gray-50"
                      >
                        <div className="shrink-0">
                          <div className="flex items-center">
                            <div className="mr-2 text-lg min-w-[17px]">
                              {li.scanned_at && "✅"}
                            </div>
                            <InventoryStatus
                              localSupplierProduct={localSupplierProduct}
                              quantityOrdered={li.quantity}
                            />
                          </div>
                        </div>
                        <div className="flex items-baseline justify-between w-full ml-1">
                          <div
                            className={`ml-2 leading-4 ${
                              scannedLineItemId === li.line_item_id
                                ? "font-semibold"
                                : ""
                            }`}
                          >
                            {title}
                          </div>
                          <div className="flex items-start ml-1 font-semibold">
                            x{li.quantity}
                            <ChevronRightIcon
                              className={`${
                                open ? "transform rotate-90" : ""
                              } ml-2 w-5 h-5 text-gray-700`}
                            />
                          </div>
                        </div>
                      </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-800">
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform -translate-y-full"
                        enterTo="transform translate-y-0"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform translate-y-0"
                        leaveTo="transform -translate-y-full"
                      >
                        <div className="px-6 pt-1 pb-4">
                          <div className="flex justify-between">
                            <div className="flex items-center">
                              <BookmarkIcon className="w-4 h-4 mr-1 text-gray-400" />
                              <span className="text-gray-600">
                                {li.shopify_product_variant?.sku || "?"}
                              </span>
                            </div>
                            <div className="text-sm text-right text-gray-500 whitespace-nowrap">
                              <span className="font-medium text-gray-900">
                                {(Math.round(li.price * 100) / 100).toFixed(2)}{" "}
                              </span>
                              €
                            </div>
                          </div>
                          <div className="flex flex-col items-center justify-center mt-2">
                            <div className="w-full mt-1">
                              <table className="w-full">
                                <tbody>
                                  {li.shopify_product_variant?.supplier_product_shopify_product_option?.map(
                                    (spspo, i) => {
                                      return (
                                        <tr
                                          key={i}
                                          className="w-full border-b border-gray-100"
                                        >
                                          <td className="px-2 py-1 font-medium">
                                            {
                                              spspo.supplier_product.supplier
                                                .name
                                            }
                                          </td>
                                          <td className="px-1 py-1 text-right">
                                            <time
                                              dateTime={
                                                spspo.supplier_product
                                                  .updated_at
                                              }
                                            >
                                              {formatRelative(
                                                new Date(
                                                  spspo.supplier_product.updated_at
                                                ),
                                                new Date(),
                                                {
                                                  locale: fr,
                                                }
                                              )}
                                            </time>
                                          </td>
                                          <td className="px-1 py-1 text-right">
                                            {spspo.supplier_product.inventory}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="flex items-center justify-between">
                            <ScanOrderLineItemFromButton
                              order={order}
                              orderLineItem={li}
                              onScannedItem={onScannedItem}
                            />
                            {shopifyProduct?.product_id && (
                              <div className="mt-5 mb-4">
                                <a
                                  href={`https://oxmoto.myshopify.com/admin/products/${shopifyProduct.product_id}`}
                                  target="_blank"
                                  className="font-medium underline outline-none text-sky-600 hover:text-sky-500"
                                  rel="noreferrer"
                                >
                                  Voir le produit sur shopify &gt;
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </Transition>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function OrderDetailsModal({
  isOpen,
  setIsOpen,
  order,
  scannedLineItemId,
  onScannedItem,
  onValidatedOrder,
}) {
  const orderStatus = getOrderStatus(order);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => setIsOpen(false)}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-xl font-medium leading-6 text-gray-900"
              >
                {order ? (
                  <div className="flex items-start justify-between">
                    <div>
                      <div>Commande #{order.order_number}</div>
                      <div className="text-sm font-normal text-gray-500">
                        <time dateTime={order.created_at}>
                          {formatRelative(
                            new Date(order.created_at),
                            new Date(),
                            {
                              locale: fr,
                            }
                          )}
                        </time>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <OrderStatusBadge order={order} />
                    </div>
                  </div>
                ) : (
                  "Pas de commande trouvée"
                )}
              </Dialog.Title>
              <div className="pt-4 mt-4 border-t">
                <LineItemList
                  order={order}
                  orderStatus={orderStatus}
                  scannedLineItemId={scannedLineItemId}
                  lineItems={order.shopify_order_line_item}
                  onValidatedOrder={onValidatedOrder}
                  onScannedItem={onScannedItem}
                />
              </div>
              <div className="pt-3 mt-4">
                <Customer
                  customer={order.shopify_customer}
                  address={order.shopify_customer_address}
                />
              </div>
              <div className="w-full mt-4 text-sm text-center">
                <a
                  href={`https://oxmoto.myshopify.com/admin/orders/${order.order_id}`}
                  target="_blank"
                  className="font-medium underline outline-none text-sky-600 hover:text-sky-500"
                  rel="noreferrer"
                >
                  Voir la commande sur shopify
                </a>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
