import Badge from "../ui/Badge";

export function getOrderStatus(order) {
  if (order.fulfillment_status === "fulfilled") {
    return "DONE_FULFILLED";
  }
  if (order.cancelled_at) {
    return "DONE_CANCELLED";
  }
  if (order.financial_status === "paid") {
    const orderLineItemsTotal = order.shopify_order_line_item.length;
    const scannedItemsTotal = order.shopify_order_line_item.filter(
      (soli) => soli.scanned_at
    ).length;
    if (scannedItemsTotal === 0) {
      return order.validated_at
        ? "AWAITING_PREPARATION"
        : "AWAITING_VALIDATION";
    }
    if (scannedItemsTotal < orderLineItemsTotal) {
      return "PREPARATION_IN_PROGRESS";
    }
    return "PREPARATION_DONE";
  }
  return {
    pending: "PAYMENT_PENDING",
    refunded: "DONE_REFUNDED",
    partially_refunded: "PARTIALLY_REFUNDED",
    voided: "DONE_VOIDED",
  }[order.financial_status];
}

function getBadgeUIElementsFromOrderStatus(orderStatus, order) {
  // we need to put the full classnames here otherwise postcss won't be able to extract them
  const [text, bgColor, dotColor, textColor] = {
    DONE_FULFILLED: [
      "Traitée",
      "bg-gray-100",
      "text-gray-400",
      "text-gray-800",
    ],
    DONE_CANCELLED: [
      "Annulée",
      "bg-gray-100",
      "text-gray-400",
      "text-gray-800",
    ],
    AWAITING_VALIDATION: [
      "En attente de validation",
      "bg-yellow-100",
      "text-yellow-400",
      "text-yellow-800",
    ],
    AWAITING_PREPARATION: [
      "Validée - En attente de préparation",
      "bg-orange-100",
      "text-orange-400",
      "text-orange-800",
    ],
    PREPARATION_IN_PROGRESS: [
      "En cours de préparation",
      "bg-blue-100",
      "text-blue-400",
      "text-blue-800",
    ],
    PREPARATION_DONE: [
      "Préparée",
      "bg-green-100",
      "text-green-400",
      "text-green-800",
    ],
    PAYMENT_PENDING: [
      "En attente de paiement",
      "bg-gray-100",
      "text-gray-400",
      "text-gray-800",
    ],
    DONE_REFUNDED: [
      "Remboursée",
      "bg-gray-100",
      "text-gray-400",
      "text-gray-800",
    ],
    PARTIALLY_REFUNDED: [
      "Partiellement remboursée",
      "bg-gray-100",
      "text-gray-400",
      "text-gray-800",
    ],
    DONE_VOIDED: ["Annulée", "bg-gray-100", "text-gray-400", "text-gray-800"],
  }[orderStatus];
  return { text, bgColor, dotColor, textColor };
}

export default function OrderStatusBadge({ order }) {
  const orderStatus = getOrderStatus(order);
  const { text, bgColor, dotColor, textColor } =
    getBadgeUIElementsFromOrderStatus(orderStatus, order);
  return (
    <Badge backgroundColor={bgColor} textColor={textColor} dotColor={dotColor}>
      {text}
    </Badge>
  );
}
