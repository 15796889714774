import { API_BASE_URL, CONNECTED_USER } from "../config";

export function login(jwtToken) {
  return fetch(`${API_BASE_URL}/v1.0/auth/login`, {
    method: "POST",
    headers: {
      Authorization: jwtToken,
    },
  })
    .then((res) => res.json())
    .then((payload) => {
      localStorage.setItem(CONNECTED_USER, JSON.stringify(payload));
    });
}

export function getJwtToken() {
  const connectedUser = JSON.parse(
    localStorage.getItem(CONNECTED_USER) || "{}"
  );
  return connectedUser.token;
}
