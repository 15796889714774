export default function Badge({
  backgroundColor,
  textColor,
  dotColor,
  children,
}) {
  return (
    <span
      className={`min-w-[92px] inline-flex items-center justify-center px-2 py-1 rounded-md text-xs font-medium ${backgroundColor} ${textColor}`}
    >
      {dotColor && (
        <svg
          className={`-ml-0.5 mr-1.5 h-2 w-2 ${dotColor}`}
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={3} />
        </svg>
      )}
      {children}
    </span>
  );
}
