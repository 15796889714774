import { useState } from "react";
import { CameraIcon } from "@heroicons/react/outline";
import Scanner from "../scanner/Scanner";
import throttle from "lodash.throttle";
import OrderScannerManual from "./OrderScannerManual";

export default function OrderScanner({ onScanned }) {
  const [showScanner, setShowScanner] = useState(false);

  function onScannedWithState(barcode) {
    onScanned(barcode);
    // setShowScanner(false);
  }

  const throttleScanned = throttle(onScannedWithState, 400, {
    leading: true,
    trailing: false,
  });

  return (
    <div className="w-full max-w-6xl px-4 mx-auto mt-10 text-right sm:px-6 lg:px-8">
      <button
        type="button"
        onClick={() => setShowScanner(!showScanner)}
        className="inline-flex items-center px-6 py-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      >
        <CameraIcon className="w-5 h-5 mr-3 -ml-1" aria-hidden="true" />
        {showScanner ? "Cacher le scanner" : "Scanner un article"}
      </button>

      {showScanner && (
        <div className="flex flex-col space-y-8 divide-y">
          <Scanner
            onScanned={(barcode) => {
              throttleScanned(barcode);
            }}
          />
          <OrderScannerManual
            onScanned={(barcode) => {
              throttleScanned(barcode);
            }}
          />
        </div>
      )}
    </div>
  );
}
