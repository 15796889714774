export const CONNECTED_USER = "CONNECTED_USER";
export const CACHED_ORDERS = "CACHED_ORDERS";

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";
export const POSTGREST_BASE_URL =
  process.env.REACT_APP_POSTGREST_BASE_URL || "http://localhost:3001";

export const SENTRY_DSN =
  process.env.REACT_APP_SENTRY_DSN ||
  "https://178273bd25f34f25b1c689926dba5670@localhost/6072944";
