import React from "react";
import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router-dom";
import { login } from "../../api/auth";

export default function Login() {
  const navigate = useNavigate();

  function onFailure(error) {
    console.log(error);
  }

  function onSuccess(user) {
    login(user.tokenId)
      .then(() => navigate("/"))
      .catch(onFailure);
  }

  return (
    <div>
      <div className="flex flex-col items-center justify-center w-full min-h-screen py-12 bg-white sm:px-6 lg:px-8">
        <div className="px-4 py-8 bg-white shadow xl:w-2/5 lg:4/5 sm:rounded-lg sm:px-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="w-auto h-32 mx-auto"
              src="https://cdn.shopify.com/s/files/1/0067/3801/8359/files/logo_site_oxmoto-01_1fbe514e-9a48-4e57-b8f7-f2295888443d.png?height=628&pad_color=fff&v=1551189520&width=1200"
              alt="Oxmoto"
            />
            <h2 className="mt-4 text-3xl font-extrabold text-center text-gray-900">
              Se connecter
            </h2>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <form className="flex justify-center space-y-6" method="POST">
              <GoogleLogin
                className="w-3/4"
                clientId="569108890475-2e08lo8pdm47j8jderpg30r139b8sc2m.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={onSuccess}
                onFailure={onFailure}
                hostedDomain={"oxmoto.fr"}
                cookiePolicy={"single_host_origin"}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
