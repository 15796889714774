import formatRelative from "date-fns/formatRelative";
import { fr } from "date-fns/locale";
import OrderStatusBadge from "./OrderStatusBadge";

export default function OrderList({
  orders,
  page,
  setPage,
  isLoading,
  onOrderClick,
}) {
  return (
    <div className="mt-8">
      <h2 className="max-w-6xl px-4 mx-auto mt-8 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
        Toutes les commandes
      </h2>

      <div className="">
        <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col mt-2">
            <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                      N°
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                      Date
                    </th>
                    <th className="hidden px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50 md:block">
                      Client
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                      Total
                    </th>
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase bg-gray-50">
                      Statut
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {orders?.map((order) => (
                    <tr key={order.order_id} className="bg-white">
                      <td className="px-6 py-2 text-sm text-gray-900 max-w-0 whitespace-nowrap hover:underline">
                        <button
                          className="flex"
                          onClick={() => onOrderClick(order)}
                        >
                          #{order.order_number}
                        </button>
                      </td>
                      <td className="px-6 py-2 text-sm text-right text-gray-500 whitespace-nowrap">
                        <time dateTime={order.created_at}>
                          {formatRelative(
                            new Date(order.created_at),
                            new Date(),
                            {
                              locale: fr,
                            }
                          )}
                        </time>
                      </td>
                      <td className="px-6 py-2 text-sm text-right text-gray-500 whitespace-nowrap">
                        {order.shopify_customer?.first_name}{" "}
                        {order.shopify_customer?.last_name}
                      </td>
                      <td className="px-6 py-2 text-sm text-right text-gray-500 whitespace-nowrap">
                        <span className="font-medium text-gray-900">
                          {(
                            Math.round(order.subtotal_price * 100) / 100
                          ).toFixed(2)}{" "}
                        </span>
                        €
                      </td>
                      <td className="hidden px-6 py-2 text-sm text-right text-gray-500 whitespace-nowrap md:block">
                        <OrderStatusBadge order={order} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination */}
              <nav
                className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    <span className="font-medium">Page {1 + page}</span>
                  </p>
                </div>
                <div className="flex justify-between flex-1 sm:justify-end">
                  <button
                    className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                    onClick={() => setPage((old) => Math.max(old - 1, 0))}
                    disabled={page === 0}
                  >
                    Précédente
                  </button>
                  <button
                    className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                    onClick={() => setPage(page + 1)}
                    disabled={isLoading || orders.length < 50}
                  >
                    Suivante
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
