import uniqBy from 'lodash.uniqby';

import { API_BASE_URL, CACHED_ORDERS, CONNECTED_USER, POSTGREST_BASE_URL } from '../config';
import { getJwtToken } from './auth';

// this will remove line breaks & whitespaces
const compact = (str) => str.replace(/\r?\n|\r| /g, "");

function getConfig() {
  const connectedUser = JSON.parse(
    localStorage.getItem(CONNECTED_USER) || "{}"
  );
  return {
    headers: {
      Authorization: `Bearer ${connectedUser.token}`,
    },
  };
}

const FETCH_ORDERS_QUERY = ({ offset = 0, limit = 50 } = {}) => `
  ${POSTGREST_BASE_URL}/shopify_order?select=
    order_id,
    order_number,
    created_at,
    cancelled_at,
    subtotal_price,
    fulfillment_status,
    financial_status,
    validated_at,
    shopify_customer(
      email,
      first_name,
      last_name
    ),
    shopify_customer_address(
      address1,
      address2,
      city,
      phone,
      zip
    ),
    shopify_order_line_item(
      line_item_id,
      quantity,
      price,
      scanned_at,
      shopify_product_variant(
        variant_id,
        title,
        sku,
        shopify_product(
          handle,
          title,
          product_id
        ),
        supplier_product_shopify_product_option(
          supplier_product(
            inventory,
            sku,
            barcode,
            updated_at,
            supplier(
              name
            )
          )
        )
      )
    )
    &order=created_at.desc
    &offset=${offset}
    &limit=${limit}
  `;

function cacheOrders(orders = []) {
  const alreadyCachedOrders =
    JSON.parse(localStorage.getItem(CACHED_ORDERS) || "[]") || [];
  const allOrders = uniqBy(
    orders.concat(alreadyCachedOrders),
    "order_id"
  ).reverse();
  localStorage.setItem(CACHED_ORDERS, JSON.stringify(allOrders));
  return { allOrders, current: orders };
}

export function fetchOrders(page = 0) {
  const limit = 50;
  const offset = limit * page;
  return fetch(compact(FETCH_ORDERS_QUERY({ limit, offset })), getConfig())
    .then((res) => res.json())
    .then(cacheOrders);
}

export function fetchOneOrder(orderId, { invalidate = true } = {}) {
  const cachedOrders =
    JSON.parse(localStorage.getItem(CACHED_ORDERS) || "[]") || [];
  const foundOrder = cachedOrders.find((o) => o.order_id === orderId);
  if (foundOrder && !invalidate) {
    return Promise.resolve({ current: [foundOrder] });
  }
  return fetch(
    compact(`${FETCH_ORDERS_QUERY()}&order_id=eq.${orderId}`),
    getConfig()
  )
    .then((res) => res.json())
    .then((orders) => cacheOrders(orders));
}

export function getOrderFromBarcode(supplierBarcode) {
  return fetch(
    compact(`
        ${POSTGREST_BASE_URL}/supplier_product?select=*,
        supplier_product_shopify_product_option(
          shopify_product_variant(
            shopify_order_line_item(
              scanned_at,
              shopify_order(
                order_id,
                created_at
              )
            )
          )
        )
        &or=(
          barcode.eq.${supplierBarcode},
          sku.eq.${supplierBarcode}
        )
    `),
    getConfig()
  )
    .then((res) => res.json())
    .then((supplierProducts) => {
      const allMatchingOrders = supplierProducts.flatMap((supplierProduct) => {
        return supplierProduct?.supplier_product_shopify_product_option?.flatMap(
          (spspo) => {
            return spspo.shopify_product_variant.shopify_order_line_item.map(
              (soli) => ({
                itemScannedAt: soli.scanned_at,
                ...soli.shopify_order,
              })
            );
          }
        );
      });

      const lastMonthOrders = allMatchingOrders
        ?.filter(
          (o) =>
            new Date(o.created_at).getTime() >
            new Date().getTime() - 1000 * 60 * 60 * 24 * 30
        )
        ?.sort((a, z) => new Date(z.created_at) - new Date(a.created_at));

      function getUnscannedOrderOrFirstOne(orders) {
        // take the newest order whose targeted barcode wasn't scanned yet
        return (
          orders.find((o) => !o.itemScannedAt) ||
          // if there is none, take the newest order with the targeted barcode (even if it's scanned)
          orders[0]
        );
      }

      const targetedOrder =
        // we take only the last month orders first because if we have
        // - a recent scanned order
        // - a very old unscanned order
        // we'll want to show the recently scanned order instead of the old one
        getUnscannedOrderOrFirstOne(lastMonthOrders) ||
        getUnscannedOrderOrFirstOne(allMatchingOrders);

      if (targetedOrder) {
        return fetchOneOrder(targetedOrder.order_id);
      }
    });
}

export function markOrderLineItemAsScanned({ orderId, lineItemId }) {
  return fetch(
    `${API_BASE_URL}/v1.0/shopify/orders/${orderId}/line_items/${lineItemId}/scan`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwtToken()}`,
      },
    }
  ).then((res) => {
    return {};
  });
}

export function validateOrder({ orderId }) {
  return fetch(`${API_BASE_URL}/v1.0/shopify/orders/${orderId}/validate`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`,
    },
  });
}
