import { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom";

import { CONNECTED_USER } from "./config";
import {
  getOrderFromBarcode,
  markOrderLineItemAsScanned,
  validateOrder,
  fetchOrders,
  fetchOneOrder,
} from "./api/orders";
import Login from "./components/auth/Login";
import OrderList from "./components/orders/OrderList";
import OrderScanner from "./components/orders/OrderScanner";
import OrderDetailsModal from "./components/orders/OrderDetailsModal";

function getConnectedUser() {
  return localStorage.getItem(CONNECTED_USER)
    ? JSON.parse(localStorage.getItem(CONNECTED_USER))
    : null;
}

function getOrderFromAPI(barcode) {
  return getOrderFromBarcode(barcode).then((res) => {
    return (res?.current || [])[0];
  });
}

function Dashboard() {
  const [orderDetailsModalIsOpen, setOrderDetailsModalIsOpen] = useState(false);
  const [scannedOrderLineItemId, setScannedOrderLineItemId] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [orderListPage, setOrderListPage] = useState(0);

  const queryClient = useQueryClient();

  const {
    isLoading,
    error,
    data: { current: orders = [] } = {},
  } = useQuery(
    ["shopifyOrders", orderListPage],
    () => fetchOrders(orderListPage),
    {
      refetchInterval: 1000 * 60,
      keepPreviousData: true,
    }
  );
  const { data: selectedOrder } = useQuery(
    ["selectedOrder", selectedOrderId],
    () => {
      return selectedOrderId
        ? fetchOneOrder(selectedOrderId, { invalidate: true }).then(
            ({ current = [] }) => current[0]
          )
        : null;
    },
    {
      refetchInterval: 1000 * 60,
      keepPreviousData: false,
    }
  );

  const currentOrders = orders.map((o) => {
    if (selectedOrder && selectedOrder.order_id === o.order_id) {
      return selectedOrder;
    }
    return o;
  });

  // here we already know which order_line_item we want to scan,
  // so we make an API call to set its scanned_at attribute to now()
  const markOrderLineItemAsScannedMutation = useMutation(
    markOrderLineItemAsScanned,
    {
      onSuccess(_, { orderId, lineItemId }) {
        setSelectedOrderId(orderId);
        setScannedOrderLineItemId(lineItemId);
        setOrderDetailsModalIsOpen(true);
        queryClient.invalidateQueries(["selectedOrder", orderId]);
      },
    }
  );

  const validateOrderMutation = useMutation(validateOrder, {
    onSuccess() {
      queryClient.invalidateQueries(["selectedOrder", selectedOrderId]);
    },
  });

  // this is when we scan a barcode (manually by typing in input or via camera),
  // we don't know yet at which order the scanned product belongs so we need to first
  // retrieve the correct order & line_order_item and then mark the item as scanned
  // via the markOrderLineItemAsScannedMutation
  const barcodeScannedMutation = useMutation(getOrderFromAPI, {
    onSuccess(order, barcode) {
      if (!order) {
        return alert(
          `Il n'y a pas de commande associée à cet article (${barcode}).`
        );
      }
      const scannedOrderLineItem = order.shopify_order_line_item.find(
        (soli) => {
          return soli.shopify_product_variant.supplier_product_shopify_product_option.some(
            (spspo) => {
              return [
                spspo.supplier_product.barcode,
                spspo.supplier_product.sku,
              ].includes(barcode);
            }
          );
        }
      );
      markOrderLineItemAsScannedMutation.mutate({
        orderId: order.order_id,
        lineItemId: scannedOrderLineItem.line_item_id,
      });
    },
  });

  useEffect(() => {
    let keyPressValue = "";
    let keyPressTimeout;

    // we do this because the team use an external barcode scanner (laser).
    // when scanning a barcode, the scanner will simulate a user typing on a keyboard:
    // -> keypress: C
    // -> keypress: O
    // -> keypress: D
    // -> keypress: Enter
    function onKeyPress(e) {
      clearTimeout(keyPressTimeout);
      if (e.key === "Enter") {
        barcodeScannedMutation.mutate(keyPressValue.trim());
        return (keyPressValue = "");
      }
      keyPressValue += e.key;
      keyPressTimeout = setTimeout(() => {
        keyPressValue = "";
      }, 1000);
    }

    // here, we detect the paste anywhere on the page, so that
    // pasting a barcode will fetch its associated orders
    function onPaste(e) {
      const pasteValue = e.clipboardData.getData("text");
      barcodeScannedMutation.mutate(pasteValue.trim());
    }

    window.addEventListener("keypress", onKeyPress);
    window.addEventListener("paste", onPaste);
    return () => {
      window.removeEventListener("keypress", onKeyPress);
      window.removeEventListener("paste", onPaste);
    };
  }, [barcodeScannedMutation]);

  const connectedUser = getConnectedUser();
  if (!connectedUser) {
    return <Navigate to="/login" />;
  }

  if (error) {
    return "Une erreur est apparue : " + error.message;
  }

  return (
    <div className="min-h-full">
      <div className="flex flex-col flex-1">
        <main className="flex-1 pb-8">
          {selectedOrder && (
            <OrderDetailsModal
              order={selectedOrder}
              scannedLineItemId={scannedOrderLineItemId}
              isOpen={orderDetailsModalIsOpen}
              setIsOpen={setOrderDetailsModalIsOpen}
              onScannedItem={({ order, orderLineItem }) => {
                markOrderLineItemAsScannedMutation.mutate({
                  orderId: order.order_id,
                  lineItemId: orderLineItem.line_item_id,
                });
              }}
              onValidatedOrder={({ order }) => {
                validateOrderMutation.mutate({
                  orderId: order.order_id,
                });
              }}
            />
          )}

          <OrderScanner
            onScanned={(barcode) => {
              setSelectedOrderId(null);
              barcodeScannedMutation.mutate(barcode);
              setOrderDetailsModalIsOpen(true);
            }}
          />

          <OrderList
            orders={currentOrders}
            page={orderListPage}
            setPage={setOrderListPage}
            isLoading={isLoading}
            onOrderClick={(order) => {
              setSelectedOrderId(order.order_id);
              setOrderDetailsModalIsOpen(true);
            }}
          />
        </main>
      </div>
    </div>
  );
}

function RedirectIfConnected() {
  const connectedUser = getConnectedUser();
  if (connectedUser) {
    return <Navigate to="/" />;
  }
  return <Login />;
}

export default function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/login" exact element={<RedirectIfConnected />}></Route>
        <Route path="/" element={<Dashboard />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
